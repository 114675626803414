import React, { useState, useEffect, useRef } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import iconarrow from "../images/sendIcon.png";
import regenerate from "../images/regenerate.png";
import appIcon from "../images/appIcon.png";
import llmIcon from "../images/llmIcon.png";
import { toast } from "react-hot-toast";
import copy from "../images/copy.png";
import plaintickicon from "../images/plaintickicon.png";
import { Icon } from "react-icons-kit";
import { chevronDown } from "react-icons-kit/oct/chevronDown";
import { ic_person_outline_outline } from "react-icons-kit/md/ic_person_outline_outline";
import { ic_file_download } from "react-icons-kit/md/ic_file_download";
import { ic_delete } from "react-icons-kit/md/ic_delete";
import stopIcon from "../images/stop.png";
import { info } from "react-icons-kit/oct/info";
import { ic_speaker_notes } from "react-icons-kit/md/ic_speaker_notes";

import { useAppData } from "../context/AppContext";
import TypingAnimation from "./TypingAnimation";
import { TypeAnimation } from "react-type-animation";
import RoundedButton from "./Common/RoundedButton";
import RoundedContainer from "./Common/RountedContainer";
import { ConversationUtils } from "../utils/conversation-utils";
import TooltipContainer from "./Common/TooltipContainer";
import MarkdownRenderer from "./MarkdownRenderer";
import apiRequest from "../api/api";
import { ic_feedback_outline } from "react-icons-kit/md/ic_feedback_outline";
import FeedBack from "./FeedBack";
const MIN_NO_OF_REFERENCES = 1;
const MAX_NO_OF_REFERENCES = 5;
const ADMIN_MAX_NO_OF_REFERENCES = 1000;
const INITIAL_LOADED_MESSAGES_COUNT = 5;
const MAXIMUM_QUESTION_CHARACTERS_COUNT = 4000;

function QuestionAnswers({
  loadingText,
  setLoadingText,
  loading,
  setLoading,
  conversations,
  setConversations,
  deleteConversations,
}) {
  const { authUser, userSelectedDocs } = useAppData();

  const ref = useRef();
  const [copyText, setCopyText] = useState([]);
  const [question, setQuestion] = useState("");
  const [textColor, setTextColor] = useState("rgb(11, 91, 140)");

  const [typing, setTyping] = useState(false); // State to track if typing is in progress
  const [typingText, setTypingText] = useState("");
  const [newAnswer, setNewAnswer] = useState(null);
  const [selectedModel, setSelectedModel] = useState("default");
  const [noOfReferences, setNoOfReferences] = useState(5);
  const [showSelectedSources, setShowSelectedSources] = useState(null);
  const [showCLearChatModal, setShowCLearChatModal] = useState(false); // State variable for showing delete modal
  const [isFormOpen, setIsFormOpen] = useState(false);

  const [supportedLlms, setSupportedLlms] = useState([]);
  const [loadedMessagesCount, setLoadedMessagesCount] = useState(
    INITIAL_LOADED_MESSAGES_COUNT
  );

  const disableClearConversion = !conversations?.length > 0;
  const handleClearChat = () => {
    deleteConversations();
    setShowCLearChatModal(false);
  };

  const handleCopy = (index) => {
    const newTextArray = [...copyText];
    newTextArray[index] = true;
    setCopyText(newTextArray);
  };

  useEffect(() => {
    if (ref.current) ref.current.scrollIntoView();
  }, [conversations]);

  useEffect(() => {
    if (newAnswer) {
      setConversations((prevConversations) => {
        // Check if there is an existing conversation with the same question and an empty answer
        const updatedConversations = prevConversations.map((conv) =>
          conv.question === newAnswer.question && conv.answer === ""
            ? { ...newAnswer } // Replace the conversation with the new answer
            : conv
        );

        // Add the new conversation if it is not already present
        if (
          !prevConversations.some(
            (conv) => conv.question === newAnswer.question
          )
        ) {
          updatedConversations.push(newAnswer);
        }

        return updatedConversations;
      });

      // Clear the newAnswer after updating the conversations
      setNewAnswer(null);
    }
  }, [newAnswer]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setCopyText([false]);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [copyText]);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView();
    }
  }, [newAnswer]);

  const handleSubmit = async (e, question) => {
    e.preventDefault();
    if (ref.current) ref.current.scrollIntoView();

    if (userSelectedDocs.length == 0) {
      toast.error("Please select one or more Documents to proceed");
      return;
    }

    if (question.trim() !== "") {
      const newQuestionData = {
        question: question.trim(),
        answer: "",
        model: selectedModel,
        documents: userSelectedDocs,
        noOfReferences: noOfReferences,
      };
      setLoadedMessagesCount(INITIAL_LOADED_MESSAGES_COUNT);
      setConversations((prevData) => [...prevData, newQuestionData]);
      setLoading(true); // Show loading indicator
      try {
        const responseData = await apiRequest(
          "POST",
          `/ask`,
          JSON.stringify({
            userid: authUser.user_id,
            question: question,
            selected_document: userSelectedDocs,
            selected_model: selectedModel,
            noOfReferences: noOfReferences,
          })
        );
        if (responseData.message == "collection doesn't exists") {
          toast.error(responseData.message);
        }

        if (responseData) {
          setLoading(false);
          // const res = await response.json();
          setNewAnswer({
            answer: responseData.answer,
            question: question,
            model: selectedModel,
            documents: userSelectedDocs,
            noOfReferences: noOfReferences,
            retrievedKnowledge: responseData.retrievedKnowledge ?? "",
            id: responseData.id,
          });
        }

        setTyping(true);
        setLoadingText(true);
        setQuestion("");
      } catch (err) {
        console.log(err, "errask");
        const askErr = err.response.data?.message;
        toast.error(askErr);
        setLoading(false);
      }
    }
  };

  // Function to get the last question
  const getLastQuestion = () => {
    const questions = conversations?.filter((item) => item.question);
    const lastItem = questions[questions.length - 1];
    return lastItem?.question || "";
  };

  const ToggleselectedSources = (index) => {
    setShowSelectedSources(showSelectedSources == index ? null : index);
  };

  const onTypingCompletion = () => {
    setTyping(false);
    // update last message answer
    let updatedConversations = [...conversations];
    updatedConversations[updatedConversations.length - 1] = {
      ...updatedConversations[updatedConversations.length - 1],
      answer: `${typingText} ...`,
    };
    setConversations([...updatedConversations]);
  };

  const noOfSelectedDocs = userSelectedDocs.length;

  useEffect(() => {
    const fetchLlmOptions = async () => {
      try {
        const responseData = await apiRequest("GET", "/getSupportedLlmOption");

        const { default_llm, supported_llms } = responseData;
        setSupportedLlms(responseData.supported_llms);

        setSelectedModel(
          default_llm?.model_id ?? supported_llms[0].model_id ?? ""
        );
      } catch (err) {
        const fetchLlmOptionsErr = err.response.data?.message;
        toast.error(fetchLlmOptionsErr);
        console.error("Error fetching LLM options:", err);
      }
    };

    fetchLlmOptions();
  }, []);

  const toggleForm = () => {
    setIsFormOpen(!isFormOpen);
  };

  const loadMoreMessages = () => {
    setLoadedMessagesCount(
      (prevValue) => prevValue + INITIAL_LOADED_MESSAGES_COUNT
    );
  };

  const MAX_N_REFERENCES = authUser.is_admin
    ? ADMIN_MAX_NO_OF_REFERENCES
    : MAX_NO_OF_REFERENCES;


  return (
    <div
      className="flex flex-col h-safe-screen overflow-y-auto"
      style={{ width: "100%", position: "relative" }}
    >
      <div
        className="absolute bg-white w-full"
        style={{ zIndex: 1, height: "58px", borderBottom: "1px solid #cdcdcd" }}
      ></div>
      <div
        className="flex gap-2 absolute right-5 z-10"
        style={{ top: "10px", right: "20px" }}
      >
        <RoundedButton
          label={"History"}
          tooltip={"Download conversation"}
          icon={<Icon icon={ic_file_download} size={18} />}
          disabled={conversations?.length == 0}
          onClick={() => {
            // TODO: download conversation history
            ConversationUtils.exportConversation(conversations);
          }}
        />
        <RoundedButton
          tooltip={"Clear conversation"}
          icon={<Icon icon={ic_delete} size={18} />}
          disabled={disableClearConversion}
          onClick={() => setShowCLearChatModal(true)}
        />
      </div>
      <div
        className="flex-1 overflow-y-auto relative"
        style={{
          width: "100%",
          padding: "60px 50px 10px",
          scrollbarWidth: "thin",
        }}
      >
        {loadedMessagesCount < conversations?.length ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <RoundedButton
              onClick={loadMoreMessages}
              label={"Load more messages"}
            />

            <div
              style={{
                height: "1px",
                borderTop: "2px dashed gray",
                margin: "5px",
                width: "300px",
              }}
            ></div>
            <div style={{ fontSize: "13px", fontWeight: 600 }}>
              Showing last {loadedMessagesCount} messages{" "}
            </div>
          </div>
        ) : null}
        {conversations?.length > 0 ? (
          <>
            {conversations
              .slice(-loadedMessagesCount)
              .map((data, index, arr) => {
                let modelUsed =
                  supportedLlms?.find((m) => m.model_id == data.model)
                    ?.model_name ??
                  data.model ??
                  "";

                return (
                  <React.Fragment key={data.id}>
                    {data.question && (
                      <div
                        data-message-author-role="user"
                        dir="auto"
                        className="my-6 mx-auto relative text-message"
                        style={{ maxWidth: "1000px" }}
                        key={`${data.id}_Q`}
                      >
                        <div className="flex flex-row gap-1 relative w-full ">
                          <div className="relative text-white font-bold max-w-[70%] rounded-xl bg-[#6f6f6f] px-5 py-2.5 dark:bg-token-main-surface-secondary">
                            {data.question}
                          </div>
                        </div>
                        <Icon
                          size={24}
                          icon={ic_person_outline_outline}
                          className="absolute -left-10 top-1 bg-secondary rounded-full p-1 text-white"
                        />
                      </div>
                    )}

                    <div
                      className="my-6 mx-auto relative"
                      style={{ maxWidth: "1000px" }}
                      key={`${data.id}_A`}
                    >
                      <div className="absolute -left-10">
                        <img className="w-8" src={appIcon} />
                      </div>

                      {/* {data.retrievedKnowledge ? (
                        <div className=" bg-silver p-5">
                          <label className=" font-bold">
                            Retrieved Knowledge
                          </label>
                          <div>{data.retrievedKnowledge}</div>
                        </div>
                      ) : null} */}

                      {arr.length - 1 === index && data.answer && typing ? (
                        <TypingAnimation
                          text={data.answer}
                          setTyping={setTyping}
                          typingText={typingText}
                          typing={typing}
                          setTypingText={setTypingText}
                        />
                      ) : (
                        data.answer && (
                          <div
                            style={{
                              backgroundColor: "#ebebeb",
                            }}
                            className="rounded-xl p-2 mt-2 mb-2 pl-4"
                          >
                            {" "}
                            {data.answer.split("\n").map((line, index) => (
                              <div className="mt-2 answers-chat " key={index}>
                                <MarkdownRenderer markdownContent={line} />
                              </div>
                            ))}
                            <div className="flex p-2 pt-4 gap-2 flex-wrap ">
                              <div className="dropdown-container">
                                <div
                                  className="cursor-pointer dc-title"
                                  onClick={() => ToggleselectedSources(index)}
                                >
                                  <span className="font-bold font-sans pr-1">
                                    Sources
                                  </span>
                                  <Icon className="pl-0" icon={chevronDown} />
                                </div>
                                <ul
                                  className={`dropdown-content ${
                                    showSelectedSources === index
                                      ? "show"
                                      : "hide"
                                  }`}
                                >
                                  {data.documents?.map((doc, i) => (
                                    <li key={i}>{`- ${doc}`}</li>
                                  ))}
                                </ul>
                              </div>
                              <div className="flex-1"></div>

                              {index == arr.length - 1 ? (
                                <RoundedContainer
                                  style={{ padding: "3px 6px" }}
                                >
                                  <Icon
                                    size={20}
                                    onClick={toggleForm}
                                    icon={ic_feedback_outline}
                                  />
                                </RoundedContainer>
                              ) : (
                                ""
                              )}

                              <RoundedContainer>
                                {copyText[index] ? (
                                  // currently being copied
                                  <TooltipContainer
                                    id="copied-text-tooltip"
                                    content={"Copied"}
                                  >
                                    <img
                                      src={plaintickicon}
                                      height={20}
                                      style={{
                                        height: "20px",
                                        width: "20px",
                                      }}
                                    />
                                  </TooltipContainer>
                                ) : (
                                  // display copy icon
                                  <TooltipContainer
                                    id="copy-text-tooltip"
                                    content={"Copy Text to clipboard"}
                                  >
                                    <CopyToClipboard
                                      text={data.answer}
                                      onCopy={() => handleCopy(index)}
                                    >
                                      <img
                                        src={copy}
                                        height={20}
                                        style={{
                                          height: "20px",
                                          width: "20px",
                                        }}
                                      />
                                    </CopyToClipboard>
                                  </TooltipContainer>
                                )}
                              </RoundedContainer>

                              {index == arr.length - 1 ? (
                                <TooltipContainer
                                  id="regenerate-response-tooltip"
                                  content={"Regenerate"}
                                >
                                  <RoundedContainer
                                    onClick={(e) =>
                                      handleSubmit(e, getLastQuestion())
                                    }
                                  >
                                    <img
                                      height={20}
                                      src={regenerate}
                                      style={{
                                        height: "20px",
                                        width: "20px",
                                      }}
                                    />
                                  </RoundedContainer>
                                </TooltipContainer>
                              ) : null}
                              <RoundedContainer>
                                <div
                                  className="flex gap-1"
                                  title={`Model used to answer your question: ${modelUsed}`}
                                >
                                  <img
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      objectFit: "scale-down",
                                    }}
                                    src={llmIcon}
                                  />
                                  <span>{modelUsed}</span>
                                </div>
                              </RoundedContainer>
                            </div>
                          </div>
                        )
                      )}
                      {index == arr.length - 1 ? (
                        <FeedBack
                          conversation={data}
                          isFormOpen={isFormOpen}
                          setIsFormOpen={setIsFormOpen}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </React.Fragment>
                );
              })}
          </>
        ) : (
          <TypeAnimation
            sequence={[
              "No Conversation available. Please ask anything!",
              1000,
              () => setTextColor("rgb(4, 28, 45"),
              "Chat with Your Docs: Ask questions, and get answers. ",
              1000,
              () => setTextColor("#F6874C"),
              "AI-Powered Insights: Leverage AI to extract valuable information from your documents.",
              1000,
              "Seamless Experience: Enjoy a user-friendly interface designed for effortless navigation.",
              1000,
              () => setTextColor("#F6874C"),
              "",
            ]}
            speed={20}
            style={{
              fontSize: "2em",
              fontWeight: "bold",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              width: "100%",
              textAlign: "center",
              marginTop: "50px",
              color: textColor,
            }}
            repeat={Infinity}
          />
        )}
        <div ref={ref} style={{ height: "64px", position: "relative" }}>
          {loading && (
            <div className="loader">
              <div className="outer"></div>
              <div className="middle"></div>
              <div className="inner"></div>
            </div>
          )}
        </div>
      </div>

      <div className="input-form">
        <form
          className="m-2 relative"
          onSubmit={(e) => handleSubmit(e, question)}
        >
          <div
            className="relative flex mx-auto rounded-2xl"
            style={{
              border: "2px solid #084c92",
              backgroundColor: "white",
              maxWidth: "1100px",
            }}
          >
            <div className="flex flex-col w-full">
              <textarea
                maxLength={MAXIMUM_QUESTION_CHARACTERS_COUNT}
                rows={1}
                disabled={loading || typing}
                style={{ opacity: loading ? 0.5 : 1, backgroundColor: "white" }}
                className="resize-none ml-3 flex-1 p-3 placeholder:text-gray font-medium focus:outline-none"
                type="text"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                placeholder={`Write a prompt (Max ${MAXIMUM_QUESTION_CHARACTERS_COUNT} characters)`}
                onKeyDown={(event) => {
                  if (
                    event.key === "Enter" &&
                    !event.shiftKey &&
                    question !== ""
                  ) {
                    handleSubmit(event, question);
                  }
                }}
                
              />
              <div className="flex justify-between items-center py-1 mx-8">
              <TooltipContainer
                id="selected-docs-tooltip"
                content={userSelectedDocs.map((doc) => doc).join(", ")}
              >
                <div className="info-msg-tile">
                  {noOfSelectedDocs == 0
                    ? "Please select one or more documents"
                    : `Responding from the selected ${
                        noOfSelectedDocs == 1 ? "" : `${noOfSelectedDocs} `
                      }document${noOfSelectedDocs > 1 ? "s" : ""}`}
                </div>
              </TooltipContainer>
              <span style={{color:question.length>=MAXIMUM_QUESTION_CHARACTERS_COUNT? "red":"green"}}>{question.length}/{MAXIMUM_QUESTION_CHARACTERS_COUNT}</span>

            </div>
            </div>

            <div className="flex items-center">
              {typing ? (
                <button className="pr-3" type="submit">
                  <img
                    src={stopIcon}
                    onClick={onTypingCompletion}
                    title="Stop"
                    height={28}
                    width={28}
                  />
                </button>
              ) : (
                <button
                  className="pr-3"
                  type="submit"
                  disabled={question == "" || loading}
                  style={{
                    opacity: question == "" || loading ? 0.3 : 0.7,
                  }}
                >
                  <img src={iconarrow} title="Send" height={28} width={28} />
                </button>
              )}
            </div>
          </div>
          <div className="flex justify-center p-2">
            <div className="flex flex-row items-center">
              <div
                className="flex bordered-item"
                style={{
                  backgroundColor: "white",
                  opacity: typing || loading ? 0.5 : 1,
                }}
              >
                <label className="font-semibold px-1">References:</label>
                <input
                  className="bg-[#e7e7e7] rounded-md pl-2 text-center"
                  type="number"
                  min={MIN_NO_OF_REFERENCES}
                  max={MAX_N_REFERENCES}
                  step="1"
                  value={noOfReferences}
                  onChange={(e) => {
                    let valueToSet = e.target.value;
                    if (valueToSet < MIN_NO_OF_REFERENCES) {
                      valueToSet = MIN_NO_OF_REFERENCES;
                    } else if (valueToSet > MAX_N_REFERENCES) {
                      valueToSet = MAX_N_REFERENCES;
                    }
                    setNoOfReferences(valueToSet);
                  }}
                />
              </div>
              <TooltipContainer
                id="no-of-references-info-tooltip"
                content={
                  "Adjust the number of references/chunks to be reviewed for answering the question."
                }
              >
                <Icon className="px-2" icon={info}></Icon>
              </TooltipContainer>
              <div
                className="flex bordered-item"
                title="Change Model"
                style={{
                  backgroundColor: "white",
                  opacity: typing || loading ? 0.5 : 1,
                }}
              >
                <img
                  disabled={typing || loading}
                  style={{
                    cursor: "pointer",
                    width: "24px",
                    objectFit: "scale-down",
                  }}
                  src={llmIcon}
                />
                <select
                  style={{
                    backgroundColor: "white",
                    border: "0",
                  }}
                  value={selectedModel}
                  disabled={typing || loading}
                  onChange={(e) => setSelectedModel(e.target.value)}
                >
                  {supportedLlms?.map((model, index) => {
                    return (
                      <option key={index} value={model.model_id}>
                        {model.model_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <TooltipContainer
                id="change-llm-info-tooltip"
                content={
                  "Select from various available language models that best suits your need."
                }
              >
                <Icon className="px-2" icon={info}></Icon>
              </TooltipContainer>
            </div>
          </div>

          <p
            className=" text-center text-secondary"
            style={{ fontWeight: "bold", fontSize: "12px" }}
          >
            Chat with Docs can make mistakes, so always verify important
            information.
          </p>
        </form>
      </div>
      {showCLearChatModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-40">
          <div
            style={{
              height: "200px",
              background: "#e6e6e6",
              position: "relative",
              width: "100%",
              maxWidth: "500px",
              marginTop: "-60px",
              padding: "20px",
              textAlign: "center",
            }}
          >
            <h4 className="text-black font-bold">Confirmation</h4>
            <p>Do you really want to clear this conversation?</p>

            <div className="flex justify-between mt-20">
              <button
                className="p-2 w-20 bg-black text-white"
                onClick={handleClearChat}
              >
                Yes
              </button>
              <button
                className="p-2  w-20 bg-black  text-white"
                onClick={() => setShowCLearChatModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default QuestionAnswers;
